/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withPrefix, graphql } from 'gatsby';

// Helpers
import { formatNumber, filterResults } from '../../helpers/helpers';
import withShareResults from '../../helpers/with-share-results';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import Section from '../../components/section';
import Form from '../../components/form';
import InputSwitch from '../../components/input-switch';
import Table from '../../components/table';
import Select from '../../components/select';
import PageHeader from '../../components/page-header';
import SummaryCard from '../../components/summary-card';
import InputField from '../../components/input-field';
import ResultListing from '../../components/result-listing';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

const StudentLoanRepayment = (props) => {
	const { getShareLink, selectedYearParam } = withShareResults();
	const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

    // React Redux hooks
	const dispatch = useDispatch();
    const figures = useSelector(state => state.yearlyFigures);
    const query = useSelector(state => state.studentLoanRepayment);

	useEffect(() => {
        return () => {
			dispatch({ type: 'RESET_YEAR' });
			dispatch({ type: 'RESET_STUDENT_LOAN_SELECTIONS' });
		}
    }, []);

	//  Applies url params on load
	useEffect(() => {
		if (selectedYearParam) {
			dispatch({ type: 'SET_YEAR', payload: selectedYearParam });
		}

		dispatch({ type: 'APPLY_STUDENT_LOAN_URL_PARAMS' });
	}, [selectedYearParam]);

    const calcGrossIncome = () => {
		switch(query.paidPer) {
			case 'year':
				return query.grossIncome;
			case 'month':
				return query.grossIncome * 12;
			case 'fortnight':
				return query.grossIncome * 26;
			case 'week':
				return query.grossIncome * 52;
			case 'day':
				return query.grossIncome * query.workingDays * 52;
			case 'hour':
				return query.grossIncome * query.workingHours * query.workingDays * 52;
			default:
		};
	};
	
    // Student Loan calculation
	// https://www.gov.uk/repaying-your-student-loan/what-you-pay
	const calcStudentLoan = () => {
        let result = 0;

        switch(query.studentLoanPlan) {
            case 'Plan1':
                if (calcGrossIncome() >= figures.plan1Threshold) {
                    result = (calcGrossIncome() - figures.plan1Threshold) * figures.plan1RepaymentRate;
                }
				break;
            case 'Plan2':
                if (calcGrossIncome() >= figures.plan2Threshold) {
                    result = (calcGrossIncome() - figures.plan2Threshold) * figures.plan2RepaymentRate;
                }
				break;
            case 'Plan1Plan2':
                if (calcGrossIncome() >= figures.plan1Threshold) {
                    result = (calcGrossIncome() - figures.plan1Threshold) * figures.plan1RepaymentRate;
                }
				break;
            case 'Postgraduate':
                if (calcGrossIncome() >= figures.postgraduateThreshold) {
                    result = (calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate;
                }
				break;
            case 'PostgraduatePlan1':
                if (calcGrossIncome() >= figures.postgraduateThreshold && calcGrossIncome() < figures.plan1Threshold) {
                    result = (calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate;
                }
                else if (calcGrossIncome() >= figures.plan1Threshold && calcGrossIncome() < figures.postgraduateThreshold) {
                    result = (calcGrossIncome() - figures.plan1Threshold) * figures.plan1RepaymentRate;
                }
                else if (calcGrossIncome() >= figures.plan1Threshold && calcGrossIncome() >= figures.postgraduateThreshold) {
                    result = ((calcGrossIncome() - figures.plan1Threshold) * figures.plan1RepaymentRate) + ((calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate);
                }
				break;
            case 'PostgraduatePlan2':
                if (calcGrossIncome() >= figures.postgraduateThreshold && calcGrossIncome() < figures.plan2Threshold) {
                    result = (calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate;
                }
                else if (calcGrossIncome() >= figures.postgraduateThreshold && calcGrossIncome() >= figures.plan2Threshold) {
                    result = ((calcGrossIncome() - figures.postgraduateThreshold) * figures.postgraduateRepaymentRate) + ((calcGrossIncome() - figures.plan2Threshold) * figures.plan2RepaymentRate);
                }
                else if (calcGrossIncome() >= figures.plan2Threshold && calcGrossIncome() < figures.postgraduateThreshold) {
                    result = (calcGrossIncome() - figures.plan2Threshold) * figures.plan2RepaymentRate;
                }
				break;
			case 'Plan4':
				if (calcGrossIncome() >= figures.plan4Threshold) {
					result = (calcGrossIncome() - figures.plan4Threshold) * figures.plan4RepaymentRate;
				}
				break;
			case 'Plan5':
				if (calcGrossIncome() >= figures.plan5Threshold) {
					result = (calcGrossIncome() - figures.plan5Threshold) * figures.plan5RepaymentRate;
				}
				break;
            default:
        };

        return result;
    };

	// Calculate Plan 2 Interest when gross income is between lower and higher threshold
	const calcPlan2InterestRange = figures.plan2BasicInterestRate + ((calcGrossIncome() - figures.plan2Threshold) * (figures.plan2AdditionalInterestRate - figures.plan2BasicInterestRate) / (figures.plan2HigherThreshold - figures.plan2Threshold));
	
	// Interest rate applied to student loan
	// https://www.gov.uk/repaying-your-student-loan/what-you-pay
	const interestRate = () => {
		let result = 0;

		switch(query.studentLoanPlan) {
			case 'Plan1':
				result = figures.plan1InterestRate;
				break;
			case 'Plan2':
				if (query.studying) {
					result = figures.plan2StudyingInterestRate;
				}
				else if (calcGrossIncome() <= figures.plan2Threshold) {
					result = figures.plan2BasicInterestRate;
				}
				else if (calcGrossIncome() > figures.plan2Threshold && calcGrossIncome() < figures.plan2HigherThreshold) {
					result = calcPlan2InterestRange;
				}
				else if (calcGrossIncome() >= figures.plan2HigherThreshold) {
					result = figures.plan2AdditionalInterestRate;
				}
				break;
			case 'Plan1Plan2':
				result = figures.plan1RepaymentRate;
				break;
			case 'Postgraduate':
				result = figures.postgraduateInterestRate;
				break;
			case 'Plan4':
				result = figures.plan4InterestRate;
				break;
			case 'Plan5':
				result = figures.plan5InterestRate;
				break;
			default:
		}

		return result;
	};

	// Total student loan remaining after n years
	const calcTotalRemainingPerYear = (n) => {
		const remainingPerYear = (query.loanRemaining * Math.pow(1 + interestRate(), n)) - (calcStudentLoan() * ((Math.pow(1 + interestRate(), n) - 1) / interestRate()));
		
		return remainingPerYear;
	};
	
	// Total repaid after n years
	const calcTotalPaidPerYear = (n) => {
		return calcStudentLoan() * n;
	};
	
	// Amount of interest added per year
	let interestAddedPerYear = [];
	const calcYearlyInterestAdded = (n) => {
		let newTotal = calcTotalRemainingPerYear(n);

		let result = newTotal * interestRate();

		interestAddedPerYear.push(result);

		return result;
	};

	// Total interest added to date
	const calcTotalInterestAddedToDate = (i) => {
		// If total remaining is 0 then add all interests stored in interest array up to the current year, else add all values
		const result = (calcTotalRemainingPerYear(i) <= 0) ? interestAddedPerYear.slice(0, i).reduce((a, b) => a + b, 0) : interestAddedPerYear.slice(0, i+1).reduce((a, b) => a + b, 0);
		
		return result;
	};

	// Number of years until student loan is written off (25 years for Plan 1, 30 years for Plan 2, Plan 4 or Postgraduate, 40 years for Plan 5)
	const maxPeriod = query.studentLoanPlan === 'Plan1' ? 25 : query.studentLoanPlan === 'Plan5' ? 40 : 30;

	// Array of table rows for yearly breakdown
	let yearlyBreakdownRow = [];
	let finalYearlyBreakdownRow;

	for (let i = 0; i < maxPeriod + 1; i++) {
		// If the total loan remaining for this year is bigger than 0
		if (calcTotalRemainingPerYear(i-1) > 0) {
			// If the next total remaining is bigger than 0
			if (calcTotalRemainingPerYear(i) >= 0 && i < maxPeriod) {
				yearlyBreakdownRow.push(
					<tr key={i}>
						<td>Year {i+1}</td>
						<td>{formatNumber(calcTotalRemainingPerYear(i), true)}</td>
						<td>{(calcTotalRemainingPerYear(i+1) <= 0) && (formatNumber(calcStudentLoan(), true) >= formatNumber(calcTotalRemainingPerYear(i), true)) ? formatNumber(calcTotalRemainingPerYear(i) + calcYearlyInterestAdded(i), true) : formatNumber(calcStudentLoan(), true)}</td>
						<td>{formatNumber(calcYearlyInterestAdded(i), true)}</td>
						<td>{(calcTotalRemainingPerYear(i) <= 0) ? formatNumber(calcTotalRemainingPerYear(i-1) + calcYearlyInterestAdded(i-1) + calcTotalPaidPerYear(i), true) : formatNumber(calcTotalPaidPerYear(i+1), true)}</td>
						<td>{formatNumber(calcTotalInterestAddedToDate(i), true)}</td>
					</tr>
				);
			}
			// Else if the next total remaining is less than 0 (i.e. you've paid back) OR reached max term
			else if (calcTotalRemainingPerYear(i) < 0 || i === maxPeriod) {
				finalYearlyBreakdownRow = (
					<tfoot key={i}>
						<tr>
							<td>Loan written off</td>
							<td>{formatNumber(calcTotalRemainingPerYear(i), true)}</td>
							<td>-</td>
							<td>-</td>
							<td>{(calcTotalRemainingPerYear(i) <= 0) ? formatNumber(calcTotalRemainingPerYear(i-1) + calcYearlyInterestAdded(i-1) + calcTotalPaidPerYear(i), true) : formatNumber(calcTotalPaidPerYear(i+1), true)}</td>
							<td>{formatNumber(calcTotalInterestAddedToDate(i), true)}</td>
						</tr>
					</tfoot>
				);
			}
		}
	}
	
	// Number of years before student loan written off
    let finalPaymentYear = yearlyBreakdownRow.length;

	const isNotPostgraduateWithOtherPlan = query.studentLoanPlan !== 'PostgraduatePlan1' && query.studentLoanPlan !== 'PostgraduatePlan2';

    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />
			
			<PageHeader
				heading={pageInfo.frontmatter.title}
				imageSource={`${withPrefix("/")}img/${headerImage}`}
				modalContent={(
					<PageContent content={pageInfo.html} />
				)}
				description={pageInfo.frontmatter.description}
				breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Personal finance', url: '/personal-finance'},
                ]}
			/>

			<Section label='Calculator'>
				<Form>
					<Form.Fieldset>
						<Form.Row>
							<InputField
								id='gross-income'
								labelText='Gross income'
								modalHeading={definitions[0].frontmatter.title}
								modalContent={<PageContent content={definitions[0].html} />}
								placeholder='0'
								symbol='currency'
								min={0}
								onChange={(event) => dispatch({
									type: 'SET_STUDENT_LOAN_GROSS_INCOME',
									payload: event.target.value,
								})}
								required
								value={query.grossIncome}
							/>

							<Select
								labelText="Paid per"
								id="paid-select"
								onChange={(event) => dispatch({
									type: 'SET_STUDENT_LOAN_PAID_PER',
									payload: event.target.value,
								})}
								value={query.paidPer}
							>
								<Select.Option value="year">Year</Select.Option>
								<Select.Option value="month">Month</Select.Option>
								<Select.Option value="fortnight">Fortnight</Select.Option>
								<Select.Option value="week">Week</Select.Option>
								<Select.Option value="day">Day</Select.Option>
								<Select.Option value="hour">Hour</Select.Option>
							</Select>

							<Select
								labelText="Tax year"
								id="tax-year-select"
								onChange={(event) => dispatch({
									type: 'SET_YEAR',
									payload: event.target.value,
								})}
								value={figures.selectedYear}
							>
								<Select.Option value='2024'>2024/25</Select.Option>
								<Select.Option value='2023'>2023/24</Select.Option>
								<Select.Option value='2022'>2022/23</Select.Option>
								<Select.Option value='2021'>2021/22</Select.Option>
								<Select.Option value='2020'>2020/21</Select.Option>
								<Select.Option value='2019'>2019/20</Select.Option>
								<Select.Option value='2018'>2018/19</Select.Option>
								<Select.Option value='2017'>2017/18</Select.Option>
								<Select.Option value='2016'>2016/17</Select.Option>
							</Select>
						</Form.Row>

						<Form.Row>
							<InputField
								id='days-per-week'
								labelText='Working days per week'
								placeholder="0"
								min={1}
								max={7}
								onChange={(event) => dispatch({
									type: 'SET_STUDENT_LOAN_WORKING_DAYS',
									payload: event.target.value,
								})}
								value={query.workingDays}
							/>
		
							<InputField
								id='hours-per-day'
								labelText='Working hours per day'
								placeholder="7"
								min={1}
								max={24}
								onChange={(event) => dispatch({
									type: 'SET_STUDENT_LOAN_WORKING_HOURS',
									payload: event.target.value,
								})}
								value={query.workingHours}
							/>
						</Form.Row>

						<Form.Row>
							<Select
								labelText="Student loan plan"
								id="student-loan-select"
								tooltipHeading={definitions[1].frontmatter.title}
								tooltip={<PageContent content={definitions[1].html} />}
								onChange={(event) => dispatch({
									type: 'SET_STUDENT_LOAN_PLAN_TYPE',
									payload: event.target.value,
								})}
								value={query.studentLoanPlan}
							>
								<Select.Option value="Plan1">Plan 1</Select.Option>
								<Select.Option value="Plan2">Plan 2</Select.Option>
								<Select.Option value="Postgraduate">Postgraduate</Select.Option>
								{parseInt(figures.selectedYear, 10) >= 2021 && (
									<Select.Option value="Plan4">Plan 4</Select.Option>
								)}
								{parseInt(figures.selectedYear, 10) >= 2023 && (
                                    <Select.Option value="Plan5">Plan 5</Select.Option>
                                )}
								<Select.Option value="Plan1Plan2">Plan 1 &amp; Plan 2</Select.Option>
								<Select.Option value="PostgraduatePlan1">Postgraduate &amp; Plan 1</Select.Option>
								<Select.Option value="PostgraduatePlan2">Postgraduate &amp; Plan 2</Select.Option>
							</Select>

							<InputField
								id='remaining-debt'
								labelText='Remaining student loan debt'
								placeholder="0"
								symbol='currency'
								min={0}
								onChange={(event) => dispatch({
									type: 'SET_STUDENT_LOAN_REMAINING_DEBT',
									payload: event.target.value,
								})}
								required
								value={query.loanRemaining}
							/>
						</Form.Row>

						<Form.Row>
							<InputSwitch
								id="studying-switch"
								onChange={() => {
									dispatch({ type: 'SET_STUDENT_LOAN_STILL_STUDYING', payload: !query.studying });
								}}
								checked={query.studying}
							>
								Currently studying
							</InputSwitch>
						</Form.Row>
					</Form.Fieldset>
				</Form>
			</Section>

			<Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
			>
				<ResultListing
					select={[
						{name: 'Year', value: 'year'},
						{name: 'Month', value: 'month'},
						{name: 'Week', value: 'week'},
						{name: 'Day', value: 'day'},
						{name: 'Hour', value: 'hour'},
					]}
					selectID='results-filter'
					selectOnChange={(event) => dispatch({
						type: 'SET_STUDENT_LOAN_FILTER_RESULTS',
						payload: event.target.value,
					})}
					summary={isNotPostgraduateWithOtherPlan ? (
						<p>Assuming a fixed gross income of <span className='result-listing__summary--emphasis'>{formatNumber(calcGrossIncome(), true)}</span> and an interest rate of <span className='result-listing__summary--emphasis'>{(interestRate() * 100).toFixed(2)}%</span>, your student loan of <span className='result-listing__summary--emphasis'>{formatNumber(query.loanRemaining, true)}</span> will be written off in <span className='result-listing__summary--emphasis'>{finalPaymentYear} years</span></p>
					) : undefined
					}
					selectValue={query.filterResults}
				>
					<ResultListing.Item
						heading='Loan repayment'
						result={filterResults(calcStudentLoan(), query.filterResults, query.workingDays, query.workingHours, false)}
						subheading={`per ${query.filterResults}`}
					/>

					{isNotPostgraduateWithOtherPlan && (
						<ResultListing.Item 
							heading='Loan written off'
							result={finalPaymentYear}
							subheading='years'
						/>
					)}
				</ResultListing>
			
				{isNotPostgraduateWithOtherPlan && (
					<SummaryCard
						id='repayment-projection'
						heading='Repayment Projection'
					>
						<Table variant='fixed-column' describedBy='results-breakdown-table'>
							<Table.Header>
								<th>Period</th>
								<th>Loan remaining</th>
								<th>Repaid this year</th>
								<th>Interest added this year</th>
								<th>Total repaid to date</th>
								<th>Total interest added to date</th>
							</Table.Header>
							<tbody>
								{yearlyBreakdownRow.length === 0 ? (
									<tr>
										<td>Year 0</td>
										<td>£0.00</td>
										<td>£0.00</td>
										<td>£0.00</td>
										<td>£0.00</td>
										<td>£0.00</td>
									</tr>
								) : yearlyBreakdownRow}
							</tbody>
							{finalYearlyBreakdownRow}
						</Table>
					</SummaryCard>
				)}
				<div className='summary-card__controls'>
					<ShareResults url={getShareLink(query, figures)} />
				</div>
			</Section>
        </Default>
    );
};

export default StudentLoanRepayment;

export const StudentLoanQuery = graphql`
    query StudentLoanQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                description
                image {
                    relativePath
                }
                title
            }
        }
		definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/gross-income|src/definitions/student-loan-plans/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;